<template>
	<header id="header" />

	<!-- Nav -->
	<nav id="nav">
		<ul>
			<li>
				Mention légale
			</li>
		</ul>
	</nav>

	<!-- Main -->
	<div id="main" class="page-legal">
		<h3>Éditeur de Alfred SMS :</h3>
		<p>
			SAS Sortable<br />
			1012 Route de l’étang<br />
			La Borderie<br />
			19190 Beynat<br />
			Téléphone : 0677003701<br />
			SAS au capital de 4000€<br />
			RCS 917 420 739 Brive<br />
			Président : Pierre Valot<br /><br />
		</p>

		<h3>Prestataires d´hébergement :</h3>
		<p>
			OVH<br />
			140 quai du Sartel<br />
			59100 Roubaix<br />
			<a href="www.ovh.com">OVH (www.ovh.com)</a>
		</p>

		<h3>Politique de remboursement :</h3>
		<p>
			Il est possible d’être remboursé de son forfait Alfred à hauteur de 50% des mois restants non consommés. Un mois non consommé est un mois durant lequel aucun SMS n’a été envoyé depuis nos serveurs. <br />
			Par exemple, pour un abonnement de 6 mois, un utilisateur qui a consommé deux mois de services, aura donc 4 mois restants et sera remboursé de la moitié de la valeur mensuelle de l’abonnement. <br />
		</p>

		<p>
			Pour toute demande de remboursement, veuillez nous contacter à l´adresse : <a href="mailto:contact@alfredsms.fr">contact@alfredsms.fr</a>.<br />
			Formule de remboursement : (taux mensuel * mois non consommés) / 2
		</p>
	</div>
</template>

<style>
#main.page-legal {
	padding: 48px;
}
</style>

<script>
export default {
	name: 'LegalView'
}
</script>
