<template>
	<header id="header" />

	<!-- Nav -->
	<nav id="nav">
		<ul>
			<li>
				<router-link :class="{ active: is_active[0] }" to="/connection/#sign-in">Se connecter</router-link>
			</li>
			<li>
				<router-link :class="{ active: is_active[1] }" to="/connection/#sign-up">S'inscrire</router-link>
			</li>
		</ul>
	</nav>

	<!-- Main -->
	<div id="main" class="page-connection">
		<v-alert class="alert" closable :text="info.message" :type="info.type" v-show="info.display" />

		<section id="sign-in" class="main" v-show="this.is_active[0]">
			<v-form v-model="sign_in.valid" @submit.prevent="do_sign_in">
				<v-container>
					<v-row>
						<v-col cols="6" md="6">
							<v-text-field clearable label="Email :" required variant="solo-filled" v-model="sign_in.email" />
						</v-col>
						<v-col cols="6" md="6">
							<v-text-field :append-icon="sign_in.show_password ? 'mdi-eye' : 'mdi-eye-off'" clearable label="Mot de passe :" :type="sign_in.show_password ? 'text' : 'password'" required variant="solo-filled" v-model="sign_in.password" @click:append="sign_in.show_password = !sign_in.show_password" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12">
							<v-btn class="mt-2" block :disabled="!sign_in.valid" type="submit" variant="elevated" v-show="!sign_in.connecting">Se connecter</v-btn>
							<v-btn class="mt-2" block disabled type="submit" variant="elevated" v-show="sign_in.connecting">Connection en cours</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</section>

		<section id="sign-up" class="main" v-show="this.is_active[1]">
			<v-form v-model="sign_up.valid" @submit.prevent="do_sign_up">
				<v-container>
					<v-row>
						<v-col cols="6" md="6">
							<v-text-field clearable :disabled="sign_up.creating_account" label="Nom :" required variant="solo-filled" v-model="sign_up.last_name" />
						</v-col>
						<v-col cols="6" md="6">
							<v-text-field clearable :disabled="sign_up.creating_account" label="Prénom :" required variant="solo-filled" v-model="sign_up.first_name" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6" md="6">
							<v-text-field clearable :disabled="sign_up.creating_account" label="Email :" required variant="solo-filled" v-model="sign_up.email" />
						</v-col>
						<v-col cols="6" md="6">
							<v-text-field clearable :disabled="sign_up.creating_account" label="Raison sociale :" required variant="solo-filled" v-model="sign_up.company_name" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6" md="6">
							<v-text-field clearable :disabled="sign_up.creating_account" label="Numéro de téléphone :" required variant="solo-filled" v-model="sign_up.phone_number" />
						</v-col>
						<v-col cols="6" md="6">
							<v-text-field :append-icon="sign_up.show_password ? 'mdi-eye' : 'mdi-eye-off'" clearable :disabled="sign_up.creating_account" label="Mot de passe :" :type="sign_up.show_password ? 'text' : 'password'" required variant="solo-filled" v-model="sign_up.password" @click:append="sign_up.show_password = !sign_up.show_password" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12">
							<v-btn class="mt-2" block :disabled="!sign_up.valid" type="submit" variant="elevated" v-show="!sign_up.creating_account">S´inscrire</v-btn>
							<v-btn class="mt-2" block disabled type="submit" variant="elevated" v-show="sign_up.creating_account">Création du compte en cours</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</section>
	</div>
</template>

<style>
div#main.page-connection > .alert {
	margin: 1em 5em 0em;
}
div#main.page-connection .main {
	border-top: none;
	padding-top: 2em;
}
</style>

<script>
import Api from '@/Api'

// @ is an alias to /src
export default {
	beforeRouteUpdate(to) {
		this.is_active[0] = to.fullPath == "/connection/#sign-in" || to.hash == ""
		this.is_active[1] = to.fullPath == "/connection/#sign-up"
	},
	data() {
		return {
			info: {
				display: false,
				message: null,
				type: 'success'
			},
			sign_in: {
				connecting: false,
				email: null,
				password: null,
				show_password: false,
				valid: false
			},
			sign_up: {
				company_name: null,
				creating_account: false,
				first_name: null,
				email: null,
				last_name: null,
				password: null,
				show_password: false,
				phone_number: null,
				valid: false
			},
			is_active: [true, false]
		}
	},
	methods: {
		do_sign_in() {
			this.sign_in.connecting = true
			let api = Api.get_instance()
			api.do_login(this.sign_in.email, this.sign_in.password)
				.then(() => {
					this.$router.push({name: 'home'})
				})
				.catch(() => {
					this.info.display = true
					this.info.message = "Erreur lors de la connexion au compte"
					this.info.type = "error"
				})
				.finally(() => {
					this.sign_in.connecting = false
				})
		},
		do_sign_up() {
			this.sign_up.creating_account = true
			let api = Api.get_instance()
			api.do_sign_up(this.sign_up)
				.then(() => {
					this.info.display = true
					this.info.message = "Le compte a été créé"
					this.info.type = "success"

					this.is_active[0] = true
					this.is_active[1] = false

					this.sign_in.email = this.sign_up.email
					this.sign_in.password = this.sign_up.password
					this.do_sign_in()

					this.sign_up.company_name = null
					this.sign_up.creating_account = false
					this.sign_up.first_name = null
					this.sign_up.email = null
					this.sign_up.last_name = null
					this.sign_up.password = null
					this.sign_up.show_password = false
					this.sign_up.phone_number = null
				})
				.catch(() => {
					this.info.display = true
					this.info.message = "Erreur lors de la création du compte"
					this.info.type = "error"
					this.sign_up.creating_account = false
				})
		}
	},
	mounted() {
		this.is_active[0] = this.$route.fullPath == "/connection/#sign-in" || this.$route.hash == ""
		this.is_active[1] = this.$route.fullPath == "/connection/#sign-up"
	},
	name: 'ConnectionView'
}
</script>
