import App from './App.vue'
import { createApp } from 'vue'
import i18n from './i18n'
import router from './router'
import vuetify from './plugins/vuetify'

createApp(App)
	.use(i18n)
	.use(router)
	.use(vuetify)
	.mount('#app')
